@use "sass:meta";
@tailwind base;
@tailwind components;
@tailwind utilities;

@include meta.load-css("rich-text.scss");

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 220 100% 25.5%;
    --primary-foreground: 210 40% 98%;

    --secondary: 221 93% 57%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --warning: 32 93% 60%;
    /* error */
    --error: 0 84.2% 60.2%;
    /* success */
    --success: 120 100% 25.5%;
    /* info */
    --info: 199 89% 53%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --foreground: 222.2 84% 4.9%;

    --chart-1: 173 58% 39%;
    --chart-2: 12 76% 61%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
}


// https://github.com/radix-ui/primitives/issues/1496
html body[data-scroll-locked] {
  --removed-body-scroll-bar-size: 0 !important;
  // margin-right: 0 !important;
  position: initial !important;
}

::-webkit-scrollbar {
  @apply w-1 h-2;
}
::-webkit-scrollbar-thumb {
  @apply bg-primary;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-primary opacity-80;
}

.bg-primary\/50:hover {
  background-color: hsl(var(--color-primary) / 0.5);
}

#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  @apply fixed left-0 top-0 z-50 h-2 w-full bg-primary;
}

#nprogress .peg {
  @apply absolute right-0 block h-full w-[100px] translate-x-0 translate-y-[-4px] rotate-[3deg] transform opacity-100;
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1600;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-top-color: #000;
  border-left-color: #000;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
  position: absolute;
  opacity: 0.5;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Example input,
.Example button {
  font: inherit;
}

.Example header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}

.Example header h1 {
  font-size: inherit;
  margin: 0;
}

.Example__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}

.Example__container__load {
  margin-top: 1em;
  color: white;
}

.Example__container__document {
  margin: 1em 0;
}

.Example__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Example__container__document .react-pdf__Page {
  margin: 1em 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.Example__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}


.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  width: 80%;
  max-width: 500px;
  height: auto;
  max-height: 500px;
  border: none;
  border-radius: 12px;
  background-color: white;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: 500;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  border-radius: 15px; /* Circular shape */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 24px; /* Adjust font size as needed */
}

.close-button:hover {
  background-color: #eee;
}

.close-button:after {
  content: 'x';
  color: black;
}

.custom-iframe {
  html {
    body {
      background-color: #000;
      main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
      }
    }
  }
}

.deceased {
  filter: grayscale(100%);
}
.image-view {
  $root: &;

  display: inline-block;
  float: none;
  max-width: 100%;
  line-height: 0;
  vertical-align: baseline;
  user-select: none;

  &__body {
    position: relative;
    display: inline-block;
    max-width: 100%;
    clear: both;
    outline: transparent solid 2px;
    transition: all 0.2s ease-in;

    &__button-wrap {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 9px;
      right: 9px;
      background: rgba(38, 38, 38, 0.6);
      border-radius: 8px;
      z-index: 4;
    }

    &:hover {
      outline-color: #ffc83d;
      .image-view__body__button-wrap {
        display: block;
      }
    }

    &--focused:hover,
    &--resizing:hover {
      outline-color: transparent;
    }

    &__placeholder {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
    }

    &__image {
      margin: 0;
      cursor: pointer !important;
    }
  }

  &.focus {
    img {
      @apply outline outline-2 outline-primary;
    }
  }
}

.image-resizer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  @apply border border-border;
  border-style: dashed;

  &__handler {
    position: absolute;
    z-index: 2;
    box-sizing: border-box;
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
    border-radius: 2px;
    @apply bg-blue-500;

    &--tl {
      top: -6px;
      left: -6px;
      cursor: nw-resize;
    }

    &--tr {
      top: -6px;
      right: -6px;
      cursor: ne-resize;
    }

    &--bl {
      bottom: -6px;
      left: -6px;
      cursor: sw-resize;
    }

    &--br {
      right: -6px;
      bottom: -6px;
      cursor: se-resize;
    }
  }
}
